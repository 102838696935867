<template>
  <div id="about">
    <div class="about_head" ref="about_head">
      <div class="cir_mask"></div>
      <div class="tagline">
        <h2 class="slogan">ABOUT US</h2>
        <h2 class="tit">关于慧点</h2>
        <span class="sep_line"></span>
        <p class="txt">
          服务于中小微企业的数字化工具，助力企业<br />在经营过程中降低成本，提高效率
        </p>
      </div>
    </div>
    <div class="sectionTwo">
      <div class="left">
        <img src="@/assets/images/about/about_pic1.jpg" alt="" />
      </div>
      <div class="right">
        <div class="rightContent" ref="sectionTwo">
          <h2 class="title queue-left">关于我们</h2>
          <p class="queue-left animate_delay01">
            九慧数字科技有限公司简称“九慧数字科技”，于2015年成立，已获得国家高新技术企业认证，具有多项知识产权和专利，并且正向跨区域发展迈进。公司核心团队成员均为银行等相关专业的高学历人才，来自高阳科技、腾讯、银行（中国银行、中信银行、平安银行等）、供应链金融公司（顺丰金融等）等，分别拥有20余年的金融IT及企业服务等行业经验积累。
          </p>
        </div>
      </div>
    </div>
    <div class="sectionThree">
      <img src="@/assets/images/about/about_pic2.jpg" alt="" />
      <div class="content">
        <div class="mission" ref="sectionThree">
          <h2 class="title queue-left">使命</h2>
          <p class="queue-left animate_delay01">
            秉承科技立业的工匠精神，九慧始终心系社会责任，致力为中小微企业提供综合服务解决方案，运用大数据、人工智能、区块链等创新科技手段，打造触手可及的普惠金融，并基于数据驱动,成为企业综合服务供应商，致力于打造一家卓越的智慧科技服务集团公司。
          </p>
        </div>
      </div>
    </div>
    <div class="sectionFour">
      <div class="content" ref="sectionFour">
        <h2 class="title queue-bottom">中小微企业的数字化工具</h2>
        <p class="queue-bottom animate_delay01">
          企业慧点，九慧打造的服务于中小微企业的数字化工具，涉及惠企补贴政策和招标/中标查询、金融服务、工商服务、税务筹划等覆盖公司从成立到壮大全流程的企业服务工具，助力企业在经营过程中降低成本，提高效率。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {}
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
    window.addEventListener('resize', this.handleResize)
    this.$refs.about_head.style.height =
      document.documentElement.clientHeight + 'px'
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll)
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize() {
      // window.console.log(document.documentElement.clientHeight)
      this.$refs.about_head.style.height =
        document.documentElement.clientHeight + 'px'
    },
    handleScroll(e) {
      // window.console.log(e.target.documentElement.scrollTop)
      if (e.target.documentElement.scrollTop >= 300) {
        this.$refs.sectionTwo.style.display = 'block'
      }
      if (e.target.documentElement.scrollTop >= 800) {
        this.$refs.sectionThree.style.display = 'block'
      }
      if (e.target.documentElement.scrollTop >= 1800) {
        this.$refs.sectionFour.style.display = 'block'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#about {
  width: 100%;
  .title {
    font-size: 28px;
    color: #444444;
  }
  p {
    font-size: 16px;
    line-height: 32px;
    color: #333333;
    margin-top: 5%;
  }
  .about_head {
    position: relative;
    width: 100%;
    // height: 920px;
    background: url(~assets/images/about/head_bg.jpg) no-repeat 50% 50%;
    background-size: cover;
    overflow: hidden;
    .cir_mask {
      position: absolute;
      width: 34%;
      padding-top: 34%;
      left: 50%;
      top: 55%;
      border: 3px solid hsla(0, 0%, 100%, 0.4);
      border-radius: 50%;
      margin-left: -17%;
      margin-top: -17%;
    }
    .tagline {
      position: absolute;
      width: 34%;
      padding-top: 34%;
      left: 50%;
      top: 55%;
      color: #fff;
      margin-left: -17%;
      margin-top: -17%;
      text-align: center;
      .slogan {
        width: 100%;
        position: absolute;
        font-size: 23px;
        white-space: nowrap;
        font-size: 22.72px;
        letter-spacing: 1.4px;
        top: 32%;
      }
      .tit {
        position: absolute;
        width: 100%;
        font-size: 33px;
        line-height: 46px;
        top: 40%;
        margin-top: 10px;
      }
      .sep_line {
        width: 56%;
        height: 1px;
        background: #fff;
        opacity: 0.6;
        position: absolute;
        left: 50%;
        top: 55%;
        margin-left: -27.8%;
      }
      .txt {
        font-size: 13px;
        line-height: 28px;
        width: 90%;
        position: absolute;
        color: #fff;
        letter-spacing: 1.4px;
        top: 59%;
        left: 50%;
        margin-left: -45%;
      }
    }
  }
  .sectionTwo {
    display: flex;
    .left {
      width: 50%;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .right {
      flex: 1;
      box-sizing: border-box;
      padding: 8% 7% 0 9%;
    }
    .rightContent {
      display: none;
    }
  }
  .sectionThree {
    width: 100%;
    position: relative;
    img {
      width: 100%;
    }
    .content {
      position: absolute;
      top: 12%;
      left: 12%;
      width: 32%;
      height: 77%;
      background-color: #fff;
      padding: 5% 5% 0;
      .mission {
        display: none;
      }
    }
  }
  .sectionFour {
    text-align: center;
    padding: 17% 28% 18%;
    .content {
      display: none;
    }
  }
  @media (min-width: 1600px) {
    .about_head {
      .tagline {
        .slogan {
          font-size: 32px;
        }
        .tit {
          font-size: 46px;
        }
        .txt {
          font-size: 18px;
          line-height: 32px;
        }
      }
    }
  }
}
</style>
